import { useMemo, useState } from "react";

const useToggle = (
  initialState: boolean = false
): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const { toggle, off, on } = useMemo(
    () => ({
      toggle: () => setState((state) => !state),
      off: () => setState(false),
      on: () => setState(true),
    }),
    []
  );

  return [state, toggle, off, on];
};

export { useToggle };
