import React, { useMemo, memo, useCallback } from "react";

import * as calendar from "../calendarUtil";
import ClubSearchSwiper from "../../ClubSearchSwiper";
import { useTranslation } from "next-i18next";

const ScheduleDay = memo(
  ({ setDayNav = Function.prototype, events, month, year, week, day }) => {
    const {
      i18n: { language },
    } = useTranslation();
    const monthNames = useMemo(
      () => calendar.MONTH_FULL_NAMES_FOR_WEEK[language], 
       
      [language]
    );
    const weekDayNames = useMemo(
      () => calendar.configLanguage[language].weekDayNames,
      [language]
    );

    //const currentDate = useMemo(() => new Date(), [])

    // const [selectedDate, setSelectedDate] = useState(null);

    // const handleDayClick = useCallback((date) => {
    //   setSelectedDate(date);

    //   // onChange(date);
    // }, []);

    const weekData = useMemo(() => {
      if (!year || !month) return;
      return calendar.getWeekData({ year, month, week });
    }, [year, month, week]);

    const dayEvents = useMemo(() => {
      if (!year || (!month && month !== 0)) return;

      return calendar.getWeekEvents({
        year,
        month,
        week,

        events,
        type: "day",
      });
    }, [year, month, week, events]);

    const onPrevPress = useCallback(() => {
      setDayNav(-1);
    }, [setDayNav]);

    const onNextPress = useCallback(() => {
      setDayNav(+1);
    }, [setDayNav]);

    return (
      <div className="schedule-calendar">
        <ClubSearchSwiper
          styles={"schedule-week"}
          onPrevPress={onPrevPress}
          onNextPress={onNextPress}
          type="week"
          newParams={{
            slidesPerView: 7,
            speed: 500,
            spaceBetween: 30,
          }}
        >
          {!!weekData.length &&
            weekData.map((date, idx) => {
              const dayOfMonth =
                new Date(date).getDate() +
                " " +
                monthNames[month].toLowerCase();
               
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleMonthChange(idx)
                  }}
                  className={
                    day === idx
                      ? "schedule-swiper-search-element active-schedule-swiper-search-element"
                      : "schedule-swiper-search-element"
                  }
                  key={idx}
                >
                  <span className={"schedule-swiper-search-element__week-day"}>
                    {weekDayNames[idx]}
                  </span>
                  <span className={"schedule-swiper-search-element__week-date"}>
                    {dayOfMonth}
                  </span>
                </div>
              );
            })}
        </ClubSearchSwiper>

        <div className="schedule-week-view__container">
          <div className="schedule-week-view__content">
            {(dayEvents &&
              dayEvents.length &&
              dayEvents.map(({ time, allEvents }, index) => {
                const timeline =
                  ("0" + (time - (time % 60)) / 60).slice(-2) +
                  ":" +
                  ("0" + (time % 60)).slice(-2);

                return (
                  time && (
                    <div key={index} className="schedule-week-timeline">
                      <div className="schedule-week-timeline__time">
                        {timeline}
                      </div>
                      <div className="schedule-week-timeline__wrapper">
                        <div className="schedule-week-timeline__inner">
                          {allEvents.map((events, idx) => {
                            return (
                              <div
                                key={index + idx}
                                className="schedule-week-timeline__day"
                              >
                                {!!events.length
                                  ? events.map(
                                      ({
                                        id,
                                        date,
                                        template: { title },
                                        color,
                                        textColor,
                                      }) => {
                                        const time =
                                          new Date(date).getHours() +
                                          ":" +
                                          (
                                            "0" + new Date(date).getMinutes()
                                          ).slice(-2);
                                        return (
                                          <div
                                            key={id}
                                            className="workout-card"
                                            style={{
                                              backgroundColor: color,
                                              color: textColor,
                                            }}
                                          >
                                            <div className="workout-card-inner">
                                              <div className="workout-card__time">
                                                {time}
                                              </div>
                                              <div className="workout-card__activity">
                                                {title}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )
                );
              })) ||
              null}
          </div>
        </div>
        <style jsx global>{`
          @import "../ScheduleWeek/ScheduleWeek.scss?5";
        `}</style>
      </div>
    );
  }
);

export { ScheduleDay };
