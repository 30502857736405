import React, { useCallback } from "react";

import { HocWithPortal } from "../../../../../../hocs/hocWithPortal";
import HeaderDefaultModal from "../../HeaderModal/HeaderDefaultModal";

function CancelTraining({ data, setCancelTrainingData }) {
  const onClose = useCallback(() => {
    setCancelTrainingData({});
  }, [setCancelTrainingData]);

  return <HeaderDefaultModal innerFull data={data} onClose={onClose} />;
}

export default HocWithPortal(CancelTraining);
