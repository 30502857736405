import React from "react";

import { AlertBlock } from "../../../../common/AlertBlock";
import {
  SUCCESS_SVG,
  NOSUCCESS_SVG,
} from "../../../../../constants/svgConstants";

const HeaderDefaultModal = ({
  data,
  onClose = () => null,
  innerFull = false,
}) => {
  const { isSuccess = false, message = "" } = data;

  return (
    <>
      {!!message && (
        <AlertBlock innerFull={innerFull} onClose={onClose}>
          <>
            {isSuccess ? SUCCESS_SVG : NOSUCCESS_SVG}

            <h2 className="sign-up-section__title sign-up-section__title--error">
              {message}
            </h2>
          </>
        </AlertBlock>
      )}
    </>
  );
};

export default HeaderDefaultModal;
