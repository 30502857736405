import React, { useCallback, memo, useEffect } from "react";
import { useToggle } from "../../../../../hooks/useToggle";
import { useTranslation } from "next-i18next";

const CardItem = memo(
  ({
    placeholder,
    onPress = Function.prototype,
    isDefault,
    initialValue = false,
    isDayFilter = false,
  }) => {
    const [t] = useTranslation();
    const [active, setToggle] = useToggle(initialValue);

    useEffect(() => {
      if (!isDayFilter) return;
      if (!initialValue && active) setToggle();
    }, [initialValue, active, isDayFilter]);

    const toggleClass = useCallback(
      (e) => {
        e.stopPropagation();
        setToggle();
        onPress();
      },
      [onPress]
    );

    return (
      <div
        className={
          isDefault
            ? "card-item card-item_default"
            : active
              ? "card-item card-item_active"
              : "card-item"
        }
        onClick={isDefault ? () => null : toggleClass}
      >
        <span className="card-item__title">{t(placeholder)}</span>
      </div>
    );
  }
);

export { CardItem };
