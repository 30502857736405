import React from "react";

export const CardButton = ({
  id,
  type = "button",
  title,
  className,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      onClick={onClick}
      {...(id && { id })}
    >
      {title}
    </button>
  );
};
