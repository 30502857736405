import React from "react";
import { useTranslation } from "next-i18next";

export default function TimelineCardHeader({ elementBySize }) {
  const [t] = useTranslation();
  return (
    <div className="schedule-header__inner">
      <div className="schedule-header__workout">{t("workout")}</div>
      {!elementBySize && (
        <div className="schedule-header__instructors">{t("instructors")}</div>
      )}
      <div className="schedule-header__details">{t("payment_status")}</div>
      {/* <div className="schedule-block-header__details">{t('details')}</div> */}
    </div>
  );
}
