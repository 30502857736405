import React, { useState, useEffect, memo, useRef, useMemo } from "react";

import { DROP_SVG } from "../../../../../constants/svgConstants";
import { useTranslation } from "next-i18next";

const DropdownItem = memo(
  ({
    items,
    type,
    initialValue,
    placeholder,
    onPress = Function.prototype,
    locale,
    filterFull,
  }) => {
    const [t] = useTranslation();
    const placeholderTitle = t(placeholder);
    const isActiveButton = useMemo(() => !!(items?.length > 0), [items]);
    const [showDropMenu, setShowDropMenu] = useState(false);
    const [value, setValue] = useState(placeholderTitle);
    const [dropValue, setDropValue] = useState([]);

    useEffect(() => {
      const title = (() => {
        if (!initialValue) return placeholderTitle;
        const activeItem = items.find(({ id }) => id == initialValue);
        if (!activeItem) return placeholderTitle;
        return type === "activity" || type === "templates"
          ? activeItem.title
          : type === "instructors"
            ? `${activeItem.name}`
            : placeholderTitle;
      })();

      setValue(title);
    }, [initialValue, placeholderTitle, type, items]);

    const wrapperRef = useRef(null);
    const elementRef = useRef(null);
    const buttonRef = useRef(null);
    const dropdownWidth = useRef(null);

    const onItemPress =
      ({ id, title }) =>
      () => {
        if (initialValue !== id) {
          setValue(title);
          onPress(id);
        } else {
          setValue(placeholder);
          onPress(0);
        }
        setShowDropMenu(false);
      };

    useEffect(() => {
      if (!showDropMenu || !wrapperRef.current || !elementRef.current) return;

      wrapperRef.current.scrollTo(0, elementRef.current.offsetTop);
    }, [showDropMenu]);

    const onDropdownMenuPress = (event) => {
      event.stopPropagation();
      setShowDropMenu(!showDropMenu);
    };

    useEffect(() => {
      if (!buttonRef?.current) return;

      if (!dropdownWidth.current || locale) {
        dropdownWidth.current + 5 < buttonRef.current.offsetWidth
          ? (dropdownWidth.current = buttonRef.current.offsetWidth + 5)
          : (dropdownWidth.current = buttonRef.current.offsetWidth);
      }
    }, [buttonRef.current, locale]);

    const [inputValue, setInputValue] = useState("");

    // фильтруем выпадающий список
    useEffect(() => {
      if (!items) return;
      if (!inputValue?.trim()) {
        setDropValue(items);
        return;
      }

      const filterData = () => {
        const filtered = ["templates", "activity"].includes(type)
          ? items.filter(({ title }) => {
              return title.toLowerCase().includes(inputValue.toLowerCase());
            })
          : items.filter((item) => {
              const { name } = item;
              return name.toLowerCase().includes(inputValue.toLowerCase());
            });

        setDropValue(filtered);
      };

      filterData();
    }, [inputValue, items, type]);

    const clearValue = () => {
      setInputValue("");
    };

    useEffect(() => {
      const setFunction = () => {
        setShowDropMenu(false);
      };
      window.addEventListener("click", setFunction);

      return () => window.removeEventListener("click", setFunction);
    }, []);

    return (
      <div className="dropdown-menu">
        <button
          className={`dropdown-menu__button ${
            !isActiveButton ? "dropdown-menu__button_default" : ""
          }`}
          onClick={isActiveButton ? onDropdownMenuPress : () => null}
          ref={buttonRef}
          style={{
            display: showDropMenu && "none",
            minWidth: !filterFull ? dropdownWidth.current : "auto",
          }}
        >
          <span className="dropdown-menu__title">{value}</span>
          {isActiveButton && DROP_SVG}
        </button>

        <div
          className="dropdown-menu__input-wrapper"
          style={{
            display: !showDropMenu && "none",
            width: !filterFull ? dropdownWidth.current : "auto",
          }}
        >
          <input
            type="text"
            className="dropdown-menu__input"
            value={inputValue}
            autoFocus={true}
            placeholder={""}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setInputValue(e.target.value)}
          />
          {inputValue && (
            <span onClick={clearValue} className="dropdown-menu__clear">
              ×
            </span>
          )}
        </div>

        {showDropMenu && !!dropValue.length && (
          <div
            className="dropdown-menu__wrapper dropdown-menu-wrapper"
            ref={wrapperRef}
          >
            <div className="dropdown-menu-wrapper-inner">
              {dropValue.map(({ id, title: activeTitle, name }) => {
                const title = type === "instructors" ? name : activeTitle;

                return (
                  <div
                    key={id}
                    ref={value === title ? elementRef : null}
                    className={
                      value === title
                        ? "dropdown-menu-wrapper__item dropdown-menu-wrapper__item_active"
                        : "dropdown-menu-wrapper__item"
                    }
                    onClick={onItemPress({ id, title })}
                  >
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export { DropdownItem };
