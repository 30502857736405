import { useState, useEffect, useCallback } from "react";

const timing = (1 / 60) * 1000;
const decay = (v) => -0.1 * ((1 / timing) ^ 4) + v;

function throttle(func, ms) {
  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

export default function useScrollBox(scrollRef) {
  const [state, setState] = useState({
    isDragging: false,
    clickStartX: 0,
    scrollStartX: 0,
    direction: 0,
    momentum: 0,
    lastScrollX: 0,
    speed: 0,
  });

  const {
    clickStartX,
    scrollStartX,
    isDragging,
    direction,
    momentum,
    lastScrollX,
    speed,
  } = state;

  const scrollWrapperCurrent = scrollRef.current;

  const handleLastScrollX = useCallback(
    throttle((screenX) => {
      setState((prev) => ({
        ...prev,
        lastScrollX: screenX,
      }));
    }, timing),
    []
  );

  const handleMomentum = useCallback(
    throttle((nextMomentum) => {
      setState((prev) => ({
        ...prev,
        momentum: nextMomentum,
      }));
      scrollRef.current.scrollLeft =
        scrollRef.current.scrollLeft + nextMomentum * timing * direction;
    }, timing),
    [scrollWrapperCurrent, direction]
  );

  useEffect(() => {
    if (!direction) return;

    if (momentum > 0.1 && !isDragging) {
      handleMomentum(decay(momentum));
    } else if (isDragging) {
      setState((prev) => ({
        ...prev,
        momentum: speed,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        direction: 0,
      }));
    }
  }, [momentum, isDragging, speed, direction, handleMomentum]);

  useEffect(() => {
    if (scrollRef.current) {
      const handleDragStart = (e) => {

        const { screenX: eScreenX } = e;
        setState((prev) => ({
          ...prev,
          clickStartX: eScreenX,
          scrollStartX: scrollRef.current.scrollLeft,
          direction: 0,
        }));
      };
      const handleDragMove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (clickStartX !== undefined && scrollStartX !== undefined) {
          const { screenX: eScreenX } = e;
          const touchDelta = clickStartX - eScreenX;
          scrollRef.current.scrollLeft = scrollStartX + touchDelta;

          if (Math.abs(touchDelta) > 1) {
            setState((prev) => ({
              ...prev,
              isDragging: true,
              speed: Math.abs((lastScrollX - eScreenX) / timing),
              direction: touchDelta / Math.abs(touchDelta),
            }));
            handleLastScrollX(eScreenX);
          }
        }
      };
      const handleDragEnd = () => {
        // if (isDragging  && clickStartX !== undefined ) {

        setState((prev) => ({
          ...prev,
          isDragging: false,
          clickStartX: undefined,
          scrollStartX: undefined,
        }));
        // }
      };

      if (scrollRef.current.ontouchstart === undefined) {
        scrollRef.current.onmousedown = handleDragStart;
        scrollRef.current.onmousemove = handleDragMove;
        scrollRef.current.onmouseup = handleDragEnd;
        scrollRef.current.onmouseleave = handleDragEnd;
      }
    }
  }, [
    scrollWrapperCurrent,
    clickStartX,
    isDragging,
    scrollStartX,
    handleLastScrollX,
    lastScrollX,
  ]);

  const onClickNext = (event) => {
    const elWidth =
      event.target
        .closest(".scroll-box")
        .querySelector(".schedule-month-days__day").offsetWidth + 10;
    const widthIter = 10;
    const lengthIter = Math.floor(elWidth / widthIter);

    let iteration = 0;
    const timerid = setInterval(() => {
      if (iteration >= lengthIter - 1) {
        clearInterval(timerid);
      } else {
        ++iteration;
      }

      scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + widthIter;
    }, 500 / lengthIter);

    if (iteration >= lengthIter) clearInterval(timerid);
  };

  const onClickPrev = (event) => {
    const elWidth =
      event.target
        .closest(".scroll-box")
        .querySelector(".schedule-month-days__day").offsetWidth + 10;
    const widthIter = 10;
    const lengthIter = Math.floor(elWidth / widthIter);

    let iteration = 0;
    const timerid = setInterval(() => {
      if (iteration >= lengthIter - 1) {
        clearInterval(timerid);
      } else {
        ++iteration;
      }
      scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - widthIter;
    }, 500 / lengthIter);

    if (iteration >= lengthIter) clearInterval(timerid);
  };

  return {
    isDragging,
    onClickNext,
    onClickPrev,
  };
}
