import React, { useMemo, memo } from "react";

import * as calendar from "../calendarUtil";
import ClubSearchSwiper from "../../ClubSearchSwiper";

import { useCallback } from "react";

import { TimelineDay, TimelineWeek, TimelineCard, TimelineEmpty } from "../";

import { useTranslation } from "next-i18next";
import { useAuth } from "../../../../../../utils/auth";

import { useRulesData } from "../../../../../../utils/apollo/reactivities";
import { CircularProgressIndicator } from "../../../../../common/CircularProgressIndicator/CircularProgressIndicator";

export const ScheduleWeek = memo(
  ({
    slug,
    setActiveBlockOfContent = Function.prototype,
    setSelectedDay = Function.prototype,
    setNav = Function.prototype,
    events,
    month,
    year,
    week,
    day,
    timeClose,
    elementBySize,
    isShowCalendar,
    queryOptions,
    setEvents,
    noActiveVisits,
  }) => {
    const {
      i18n: { language },
    } = useTranslation();

    const { isAuthorized } = useAuth();

    const rulesAccepted = useRulesData();

    const { monthNames, weekDayNames } = useMemo(() => {
      const { weekDayNames, monthNamesForWeekShort } =
        calendar.configLanguage[language];

      const names = { weekDayNames, monthNames: [] };
      if (!elementBySize)
        return (
          (names.monthNames = calendar.MONTH_FULL_NAMES_FOR_WEEK[language]),
          names
        );
      return (names.monthNames = monthNamesForWeekShort), names;
    }, [language, elementBySize]);

    const weekData = useMemo(() => {
      if (!year || (!month && month !== 0) || !slug || slug === "list")
        return [];
      return calendar.getWeekData({ year, month, week, type: slug });
    }, [year, month, week, slug]);

    const cardsEvents = useMemo(() => {
      if (slug === "list") {
        if (!events?.length) return [];

        return calendar.getCardEvents({
          events,
          type: slug,
        });
      }

      //? day and week
      if (
        !year ||
        (slug === "day" && !month && month !== 0) ||
        !["week", "day"].includes(slug)
      )
        return [];

      return calendar.getWeekEvents({
        events,
        type: slug,
        timeClose,
      });
    }, [year, month, week, slug, events]);

    const onPrevPress = useCallback(() => setNav(-1), [setNav]);

    const onNextPress = useCallback(() => setNav(+1), [setNav]);

    const sliderClick = useCallback(
      ({ e, date: dateClicked }) => {
        e.stopPropagation();
        const date = new Date(dateClicked);
        if (slug === "week") {
          setActiveBlockOfContent("day");
          setSelectedDay({ week, date, type: "week" });
        } else {
          setSelectedDay({ date });
        }
      },
      [slug, week, day]
    );

    return (
      <div className="schedule-calendar">
        {/* Выбор дня в листалке */}
        {slug !== "list" && (
          <ClubSearchSwiper
            styles={
              slug === "week"
                ? "schedule-only-week-swiper"
                : "schedule-week-swiper"
            }
            onPrevPress={onPrevPress}
            onNextPress={onNextPress}
            type="week"
            newParams={{
              activeSlideKey: `${day}`,
              breakpoints: {
                1199: {
                  slidesPerView: 7,
                },

                991: {
                  slidesPerView: 6,
                },
                840: {
                  slidesPerView: 5,
                },

                767: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },

                480: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                310: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                0: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
              },
              slidesPerView: 7,
              speed: 500,
              spaceBetween: slug === "week" ? 10 : 30,
            }}
          >
            {weekData?.map((date, idx) => {
              const dayOfMonth =
                new Date(date).getDate() +
                " " +
                monthNames[new Date(date).getMonth()].toLowerCase();

              return (
                <div
                  onClick={(e) => sliderClick({ e, date })}
                  className={
                    (slug === "day" || slug === "week") && day == idx //currentDay
                      ? "schedule-swiper-search-element active-schedule-swiper-search-element"
                      : "schedule-swiper-search-element"
                  }
                  key={String(idx)}
                >
                  <span className={"schedule-swiper-search-element__week-day"}>
                    {weekDayNames[idx]}
                  </span>
                  <span className={"schedule-swiper-search-element__week-date"}>
                    {dayOfMonth}
                  </span>
                </div>
              );
            })}
          </ClubSearchSwiper>
        )}
        {/* Выбор дня в листалке */}

        <div className="schedule-week">
          <div className="schedule-week__container">
            <div className="schedule-week__content">
              {(!isShowCalendar && <CircularProgressIndicator />) ||
                (slug === "week" &&
                  events &&
                  !!cardsEvents?.length &&
                  cardsEvents.map(({ time, allEvents }, index) => {
                    return (
                      !!time && (
                        <TimelineWeek
                          key={index}
                          allEvents={allEvents}
                          time={time}
                        />
                      )
                    );
                  })) ||
                (slug === "day" &&
                  !!cardsEvents?.length &&
                  cardsEvents.map(({ time, allEvents }, index) => {
                    return (
                      !!time && (
                        <TimelineDay
                          key={index}
                          allEvents={allEvents}
                          time={time}
                          {...{
                            isAuthorized,
                            rulesAccepted,
                            queryOptions,
                            setEvents,
                          }}
                        />
                      )
                    );
                  })) ||
                (slug === "list" &&
                  !!cardsEvents?.length &&
                  cardsEvents.map((card, index) => (
                    <TimelineCard
                      key={index}
                      {...{
                        ...card,
                        isAuthorized,
                        rulesAccepted,
                        queryOptions,
                        setEvents,
                      }}
                    />
                  ))) || <TimelineEmpty noActiveVisits={noActiveVisits} />}
            </div>
          </div>
        </div>
        <style jsx global>{`
          @import "./ScheduleWeek.scss?5";
          @import "../ScheduleDay/ScheduleDay.scss?4";
          @import "../ScheduleCards/ScheduleCards.scss";
          @import "../ScheduleMonth/ScheduleMonth.scss?5";
        `}</style>
      </div>
    );
  }
);
