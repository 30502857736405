import React, { memo, useMemo, useContext } from "react";
import PropTypes from "prop-types";

import * as calendar from "./calendarUtil";

import TimelineDay from "./TimelineDay";

import { MediaElementContext } from "../ScheduleBlock";
import TimelineCardHeader from "./TimelineCardHeader";

import { useTranslation } from "next-i18next";

const TimelineCard = memo(
  ({
    dateTime,
    allEvents,
    isAuthorized,
    isHistory,
    isProfile,
    rulesAccepted = true,
    setOnCancel,
    profileId,
    queryOptions,
    setEvents,
  }) => {
    const {
      i18n: { language },
    } = useTranslation();
    const { elementBySize } = useContext(MediaElementContext);

    const { monthNamesWithCapital, weekDayNames } = useMemo(() => {
      const weekDayNames = calendar.configLanguage[language].weekDayNames,
        monthNamesWithCapital =
          calendar.configLanguage[language].monthNamesForWeekWithCapital;
      return {
        monthNamesWithCapital,
        weekDayNames,
      };
    }, [language]);

    const { dateblock } = useMemo(() => {
      if (!dateTime || !monthNamesWithCapital) return {};
      const date = new Date(dateTime),
        monthName = monthNamesWithCapital[date.getMonth()],
        dateblock = `${monthName} ${date.getFullYear()}`;

      return { dateblock };
    }, [dateTime, monthNamesWithCapital]);

    return (
      <div className="schedule-card-timeline">
        {elementBySize !== "mobile" && (
          <div className="schedule-card-timeline__header schedule-header">
            <div className="schedule-header__date">{dateblock}</div>

            {(isHistory || isProfile) && (
              <TimelineCardHeader elementBySize={elementBySize} />
            )}
          </div>
        )}
        {allEvents?.map(({ datelineTime, time, events }, index) => {
          if (time) {
            const date = new Date(datelineTime);
            const monthName = monthNamesWithCapital[date.getMonth()];
            const DayWeek = calendar.getDayOfWeek(date);

            const dateline = `${weekDayNames[DayWeek]} ${(
              "0" + date.getDate()
            ).slice(-2)} ${monthName}`;

            return (
              <TimelineDay
                key={datelineTime + index}
                allEvents={events}
                time={time}
                dateline={dateline}
                isAuthorized={isAuthorized}
                isHistory={isHistory}
                monthNamesWithCapital={monthNamesWithCapital}
                rulesAccepted={rulesAccepted}
                isProfile={isProfile}
                setOnCancel={setOnCancel}
                profileId={profileId}
                queryOptions={queryOptions}
                setEvents={setEvents}
              />
            );
          }
        })}
      </div>
    );
  }
);

TimelineCard.propTypes = {
  dateTime: PropTypes.number,
  allEvents: PropTypes.array,
  isAuthorized: PropTypes.bool,
  isHistory: PropTypes.bool,
};

TimelineCard.defaultProps = {
  dateTime: 0,
  allEvents: [],
  isAuthorized: false,
  isHistory: false,
};

export default TimelineCard;
