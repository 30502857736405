import React, { memo, useMemo, useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";

// import { Rating } from '../Rating';
// import { CHREST_SVG } from '../../constants/svgConstants';
// import { STAR_100_SVG } from "../../constants/svgConstants";

import { MediaElementContext } from "../ScheduleBlock";

import {
  TIME_MINUTES_TITLE,
  TIME_TITLE,
} from "./ScheduleWeek/languageConstants";
import { cancelIsOver } from "../../helpers/visitsUtils";
import { CancelButton } from "../TrainingSection/TrainingCancelSection/CancelButton";

import clsx from "clsx";
import { CardButton } from "../../../../common/CardButton/CardButton";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import CancelTraining from "../TrainingSection/CancelTraining/CancelTraining";

const TimelineDay = memo(
  ({
    allEvents,
    time,
    dateline,
    isProfile = false,
    isAuthorized,
    isHistory,
    setOnCancel = () => null,
    profileId,
    queryOptions,
    setEvents,
  }) => {
    const [t] = useTranslation();
    const router = useRouter();

    const { query: { club, zone: activeZone = 0 } = ({} = {}) } = router;

    const { elementBySize } = useContext(MediaElementContext);

    const timeline = useMemo(() => {
      if (!time) return "";

      return `${dateline ? `${dateline}, ` : ""}${(
        "0" +
        (time - (time % 60)) / 60
      ).slice(-2)}:${("0" + (time % 60)).slice(-2)}`;
    }, [time, dateline]);

    const ButtonEl = useCallback(
      ({
        cancelStatusOver,
        visitId,
        refundable,
        paid,
        id,
        setOnCancelTraining,
        profileId,
        queryOptions,
        setEvents,
        t,
      }) => {
        const className = "schedule-timeline__registration-btn";

        if (isAuthorized && visitId && refundable && !cancelStatusOver) {
          return (
            <CancelButton
              className={className}
              title={t("cancel")}
              setCancelTrainingData={setOnCancelTraining}
              paid={paid}
              visitId={visitId}
              id={id}
              familyId={profileId}
              queryOptions={queryOptions}
              setEvents={setEvents}
            />
          );
        }

        return (
          <CardButton
            className={`schedule-timeline__registration-btn schedule-timeline__registration-btn--hidden`}
          />
        );
      },
      []
    );

    const [cancelTrainingData, setCancelTrainingData] = useState({});

    const setOnCancelTraining = useCallback(
      (msg) => {
        isProfile ? setOnCancel(msg) : setCancelTrainingData(msg);
      },
      [isProfile, setOnCancel, setCancelTrainingData]
    );

    return (
      <>
        {!isProfile && !!cancelTrainingData?.message && (
          <CancelTraining
            data={cancelTrainingData}
            setCancelTrainingData={setCancelTrainingData}
          />
        )}

        {allEvents?.map(
          ({
            key,
            id,
            // price,
            date,
            status,
            instructors = [],
            activity = [],
            color,
            textColor,
            title,
            visitSet = [],
            duration,
            enrollEnabled = false,
            zone = {},
            template: { declineDeadline } = {},
          }) => {
            const keyId = key || id;
            // console.log(status);
            const cancelStatusOver = cancelIsOver({
              isHistory,
              date,
              declineDeadline,
            });

            const isNotActive = isHistory || !enrollEnabled;

            const trainingHours = Math.floor(duration / 60),
              trainingTime = !duration
                ? ""
                : duration < 60
                  ? `${duration} ${t(TIME_MINUTES_TITLE(duration))}`
                  : duration % 60 === 0
                    ? `${trainingHours} ${t(TIME_TITLE(trainingHours))}`
                    : `${trainingHours} ${t(TIME_TITLE(trainingHours))} 
    ${duration % 60} ${t(TIME_MINUTES_TITLE(duration % 60))}`;

            const activityTitle = activity?.title;

            return (
              <div
                className={clsx("schedule-timeline schedule-day-timeline", {
                  "schedule-timeline--disable": isNotActive,
                })}
                key={keyId}
              >
                <Link
                  href={`/club/${club}/event/${id}`}
                  className="schedule-timeline__wrapper"
                >
                  <div className="schedule-timeline__time">{timeline}</div>
                  <div className="schedule-timeline__inner">
                    <div
                      className={clsx(
                        "schedule-timeline__train schedule-timeline-train"
                      )}
                    >
                      <div className="schedule-timeline__activity schedule-timeline-activity">
                        <div
                          className="workout-card timeline-workout-card"
                          style={{
                            backgroundColor: color,
                            color: textColor,
                          }}
                        >
                          <div className="workout-card-inner">
                            {elementBySize === "mobile" ? (
                              <>
                                <p className="workout-card__activity">
                                  {`${timeline}\u00A0\u00A0\u00A0${title}\u00A0\u00A0`}
                                  {!activeZone && zone?.title && (
                                    <span className="workout-card__activity_span">
                                      ({zone.title})
                                    </span>
                                  )}
                                </p>
                              </>
                            ) : (
                              <p className="workout-card__activity">
                                {`${title}\u00A0\u00A0`}
                                {!activeZone && zone?.title && (
                                  <span className="workout-card__activity_span">
                                    ({zone.title})
                                  </span>
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        {activityTitle && (
                          <span className="schedule-timeline__name-activity">
                            {activityTitle}
                          </span>
                        )}
                      </div>

                      <div className="schedule-timeline__instructors-wrapper">
                        <div className="schedule-timeline__instructors-list">
                          {instructors?.map(({ name, id }) => {
                            return (
                              <div
                                key={id}
                                className="schedule-timeline__instructor schedule-timeline__instructor--mobile"
                              >
                                {name}
                              </div>
                            );
                          })}

                          <div className="schedule-timeline__instructor schedule-timeline__instructor-time">
                            {`(${trainingTime})`}
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx("schedule-timeline__wrapper-reg", {
                          "schedule-timeline__wrapper-reg--full-mobile":
                            cancelStatusOver,
                          "schedule-timeline__wrapper-reg--part-mobile":
                            !cancelStatusOver,
                        })}
                      >
                        <div className="schedule-timeline__reservation schedule-timeline-reservation">
                          {!!status && (!isNotActive || !visitSet.length) && (
                            <>
                              <div className="schedule-timeline__reservation-status">
                                {status}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <CardButton
                        className={`schedule-timeline__registration-btn schedule-timeline__registration-btn--hidden`}
                      />
                    </div>

                    {visitSet.map(
                      ({
                        id: visitId,
                        refundable,
                        paid,
                        user: { firstName, lastName } = {},
                        // paidByCard,
                        clientPresent,
                      }) => {
                        return (
                          <div
                            key={visitId}
                            className={clsx(
                              "schedule-timeline__train schedule-timeline-train schedule-timeline-visit"
                              // {
                              //   "schedule-timeline-train--disable":
                              //     !refundable,
                              // }
                            )}
                          >
                            {/* <Link href={`/club/${club}/event/${id}`}> */}
                            <div className="schedule-timeline__activity schedule-timeline-activity">
                              <div className="workout-card timeline-workout-card">
                                <div className="workout-card-inner">
                                  <p className="workout-card__activity">
                                    {`${firstName} ${lastName}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* </Link> */}

                            <div
                              className={clsx(
                                "schedule-timeline__wrapper-reg",
                                {
                                  "schedule-timeline__wrapper-reg--full-mobile":
                                    cancelStatusOver,
                                  "schedule-timeline__wrapper-reg--part-mobile":
                                    !cancelStatusOver,
                                }
                              )}
                            >
                              <div className="schedule-timeline__reservation schedule-timeline-reservation">
                                {!!status && (
                                  <>
                                    <div className="schedule-timeline__reservation-status">
                                      {status}
                                      {/* {" "}
                                  {price && isShowablePriceByPaid(paid) && (
                                    <span>({price})</span>
                                  )} */}
                                    </div>
                                    {clientPresent === false && (
                                      <div className="schedule-timeline__reservation-present">
                                        ({t("missed")})
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                              {/* {paidByCard && (
                              <div className="schedule-timeline__reservation schedule-timeline-card-info">
                                <div className="schedule-timeline__reservation-status">
                                  {infoTextByCard(
                                    paidByCard,
                                    monthNamesWithCapital
                                  )}
                                </div>
                              </div>
                            )} */}
                            </div>

                            <ButtonEl
                              cancelStatusOver={cancelStatusOver}
                              paid={paid}
                              refundable={refundable}
                              id={id}
                              visitId={visitId}
                              setOnCancelTraining={setOnCancelTraining}
                              profileId={profileId}
                              queryOptions={queryOptions}
                              setEvents={setEvents}
                              t={t}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        )}
      </>
    );
  }
);

TimelineDay.propTypes = {
  isAuthorized: PropTypes.bool,
  allEvents: PropTypes.array,
  time: PropTypes.number,
  dateline: PropTypes.string,
};

TimelineDay.defaultProps = {
  isAuthorized: false,
  allEvents: [],
  time: 0,
  dateline: "",
};

export default TimelineDay;
