import { ScheduleWeek, ScheduleMonth } from "../ToggleBlockOfData";
import { DropdownItem, CardItem } from "../DropDownItem";

export const TOGGLE_SCHEDULE_BUTTONS = [
  { slug: "list", name: "by_list" },
  { slug: "day", name: "by_day" },
  { slug: "week", name: "by_week", onlyDesctop: true },
  { slug: "month", name: "by_month", onlyDesctop: true },
];

export const TOGGLE_SCHEDULE_FILTER_BUTTONS = [
  // { slug: "trains", placeholder: "my_visits", Component: CardItem },
  { slug: "templates", placeholder: "by_training", Component: DropdownItem },
  {
    slug: "activity",
    placeholder: "choose_activity",
    Component: DropdownItem,
  },
  {
    slug: "instructors",
    placeholder: "by_instructor",
    Component: DropdownItem,
  },
  { slug: "morning", placeholder: "by_morning", Component: CardItem },
  { slug: "afternoon", placeholder: "by_day", Component: CardItem },
  { slug: "evening", placeholder: "by_evening", Component: CardItem },
];

export const TOGGLE_SCHEDULE_FILTER_VISITS = [
  { slug: "trains", placeholder: "my_visits", Component: CardItem },
];

export const TOGGLE_BLOCKS = [
  { slug: "list", Component: ScheduleWeek },
  { slug: "day", Component: ScheduleWeek },
  { slug: "week", Component: ScheduleWeek },
  { slug: "month", Component: ScheduleMonth },
];
