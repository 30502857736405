const DAYS_IN_WEEK = 7;

const CLOSE_TIME = 24 * 60;

export const WEEK_DAYS_FROM_MONDAY = [6, 0, 1, 2, 3, 4, 5];
export const WEEK_DAYS_FROM_MONDAY_NAMES = [
  "sun",
  "mon",
  "tues",
  "wed",
  "thurs",
  "fri",
  "sat",
];

export const convertDayToShortName = (dayNumber) => {
  const weekDayNumber = WEEK_DAYS_FROM_MONDAY.findIndex((i) => i == dayNumber);
  return WEEK_DAYS_FROM_MONDAY_NAMES[~weekDayNumber ? weekDayNumber : 1];
};

export const convertDayNameToNumber = (day) => {
  const dayNameNumber = WEEK_DAYS_FROM_MONDAY_NAMES.findIndex((i) => i === day);
  if (!~dayNameNumber) return null;

  return WEEK_DAYS_FROM_MONDAY[dayNameNumber];
};

export const MONTH_SHORT_WITH_CAPITAL = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_FULL_NAMES_FOR_WEEK = {
  en: [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ],
  ru: [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ],

  uk: [
    "січня",
    "лютого",
    "березня",
    "квітня",
    "травня",
    "червня",
    "липня",
    "серпня",
    "вересня",
    "жовтня",
    "листопада",
    "грудня",
  ],
  es: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "deciembre",
  ],
  kz: [
    "қаңтар",
    "ақпан",
    "наурыз",
    "сәуір",
    "мүмкін",
    "маусым",
    "шілде",
    "тамыз",
    "қыркүйек",
    "қазан",
    "қараша",
    "желтоқсан",
  ],
  pl: [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień",
  ],
  de: [
    "januar",
    "februar",
    "märz",
    "april",
    "mai",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "dezember",
  ],
  ka: [
    "იანვარი",
    "თებერვალი",
    "მარტი",
    "აპრილი",
    "მაისი",
    "ივნისი",
    "ივლისი",
    "აგვისტო",
    "სექტემბერი",
    "ოქტომბერი",
    "ნოემბერი",
    "დეკემბერი",
  ],
  tr: [
    "ocak",
    "şubat",
    "mart",
    "nisan",
    "mayıs",
    "haziran",
    "temmuz",
    "ağustos",
    "eylül",
    "ekim",
    "kasım",
    "aralık",
  ],
};

export const configLanguage = {
  en: {
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesForWeek: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesForWeekWithCapital: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    monthNamesForWeekShort: [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "May.",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sep.",
      "Oct.",
      "Nov.",
      "Dec.",
    ],
    weekDayNames: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  ru: {
    monthNames: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    monthNamesForWeek: [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ],
    monthNamesForWeekWithCapital: [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
    monthNamesForWeekShort: [
      "янв.",
      "фев.",
      "мар.",
      "апр.",
      "мая.",
      "июн.",
      "июл.",
      "авг.",
      "сен.",
      "окт.",
      "ноя.",
      "дек.",
    ],
    weekDayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
  },
  uk: {
    monthNames: [
      "Січень",
      "Лютий",
      "Березень",
      "Квітень",
      "Травень",
      "Червень",
      "Липень",
      "Серпень",
      "Вересень",
      "Жовтень",
      "Листопад",
      "Грудень",
    ],
    monthNamesForWeek: [
      "січня",
      "лютого",
      "березня",
      "квітня",
      "травня",
      "червня",
      "липня",
      "серпня",
      "вересня",
      "жовтня",
      "листопада",
      "грудня",
    ],
    monthNamesForWeekWithCapital: [
      "Cіч",
      "Лют",
      "Бер",
      "Кві",
      "Тра",
      "Чер",
      "Лип",
      "Сер",
      "Вер",
      "Жов",
      "Лис",
      "Гру",
    ],
    monthNamesForWeekShort: [
      "січ.",
      "лют.",
      "бер.",
      "кві.",
      "тра.",
      "чер.",
      "лип.",
      "сер.",
      "вер.",
      "жов.",
      "лис.",
      "гру.",
    ],
    weekDayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
  },
  es: {
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Deciembre",
    ],
    monthNamesForWeek: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "deciembre",
    ],
    monthNamesForWeekWithCapital: [
      "Enero",
      "Feb",
      "Mar",
      "Abr",
      "Mayo",
      "Jun",
      "Jul",
      "Agosto",
      "Set",
      "Oct",
      "Nov",
      "Dic",
    ],
    monthNamesForWeekShort: [
      "enero.",
      "feb.",
      "mar.",
      "abr.",
      "mayo.",
      "jun.",
      "jul.",
      "agosto.",
      "set.",
      "oct.",
      "nov.",
      "dic.",
    ],
    weekDayNames: ["Lun", "Mart", "Miérc", "Juev", "Vier", "Sáb", "Dom"],
  },
  kz: {
    monthNames: [
      "Қаңтар",
      "Ақпан",
      "Наурыз",
      "Сәуір",
      "Мамыр",
      "Маусым",
      "Шілде",
      "Тамыз",
      "Қыркүйек",
      "Қазан",
      "Қараша",
      "Желтоқсан",
    ],
    monthNamesForWeek: [
      "қаңтар",
      "ақпан",
      "наурыз",
      "сәуір",
      "мүмкін",
      "маусым",
      "шілде",
      "тамыз",
      "қыркүйек",
      "қазан",
      "қараша",
      "желтоқсан",
    ],
    monthNamesForWeekWithCapital: [
      "Қаң",
      "Ақп",
      "Нау",
      "Сәу",
      "Мүм",
      "Мау",
      "Шіл",
      "Мам",
      "Кырк",
      "Қаз",
      "қар",
      "Желт",
    ],
    monthNamesForWeekShort: [
      "Қаң.",
      "Ақп.",
      "Нау.",
      "Сәу.",
      "Мүм.",
      "Мау.",
      "Шіл.",
      "Мам.",
      "Кырк.",
      "Қаз.",
      "қар.",
      "Желт.",
    ],
    weekDayNames: ["Дү", "Сй", "Ср", "Бе", "Жұ", "Сн", "Же"],
  },
  pl: {
    monthNames: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
    monthNamesForWeek: [
      "styczeń",
      "luty",
      "marzec",
      "kwiecień",
      "maj",
      "czerwiec",
      "lipiec",
      "sierpień",
      "wrzesień",
      "październik",
      "listopad",
      "grudzień",
    ],
    monthNamesForWeekWithCapital: [
      "Sty",
      "Lut",
      "Mar",
      "Kwi",
      "Maj",
      "Cze",
      "Lip",
      "Sie",
      "Wrz",
      "Paź",
      "Lis",
      "Gru",
    ],
    monthNamesForWeekShort: [
      "Sty.",
      "Lut.",
      "Mar.",
      "Kwi.",
      "Maj.",
      "Cze.",
      "Lip.",
      "Sie.",
      "Wrz.",
      "Paź.",
      "Lis.",
      "Gru.",
    ],
    weekDayNames: ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Nie"],
  },
  de: {
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    monthNamesForWeek: [
      "januar",
      "februar",
      "märz",
      "april",
      "mai",
      "juni",
      "juli",
      "august",
      "september",
      "oktober",
      "november",
      "dezember",
    ],
    monthNamesForWeekWithCapital: [
      "Jan",
      "Feb",
      "März",
      "Apr",
      "Mai",
      "Juni",
      "Juli",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    monthNamesForWeekShort: [
      "Jan.",
      "Feb.",
      "Mär.",
      "Apr.",
      "Mai.",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sep.",
      "Okt.",
      "Nov.",
      "Dez.",
    ],
    weekDayNames: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "Son"],
  },

  ka: {
    monthNames: [
      "იანვარი",
      "თებერვალი",
      "მარტი",
      "აპრილი",
      "მაისი",
      "ივნისი",
      "ივლისი",
      "აგვისტო",
      "სექტემბერი",
      "ოქტომბერი",
      "ნოემბერი",
      "დეკემბერი",
    ],
    monthNamesForWeek: [
      "იანვარი",
      "თებერვალი",
      "მარტი",
      "აპრილი",
      "მაისი",
      "ივნისი",
      "ივლისი",
      "აგვისტო",
      "სექტემბერი",
      "ოქტომბერი",
      "ნოემბერი",
      "დეკემბერი",
    ],
    monthNamesForWeekWithCapital: [
      "იან",
      "თებ",
      "მარ",
      "აპრ",
      "მაი",
      "ივნ",
      "ივლ",
      "აგვ",
      "სექ",
      "ოქტ",
      "ნოე",
      "დეკ",
    ],
    monthNamesForWeekShort: [
      "იან.",
      "თებ.",
      "მარ.",
      "აპრ.",
      "მაი.",
      "ივნ.",
      "ივლ.",
      "აგვ.",
      "სექ.",
      "ოქტ.",
      "ნოე.",
      "დეკ.",
    ],
    weekDayNames: ["ორშ", "სკ", "ოთ", "ხუ", "პარ", "შაბ", "კვ"],
  },
  tr: {
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesForWeek: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesForWeekWithCapital: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    monthNamesForWeekShort: [
      "Oca.",
      "Şub.",
      "Mar.",
      "Nis.",
      "May.",
      "Haz.",
      "Tem.",
      "Ağu.",
      "Eyl.",
      "Eki.",
      "Kas.",
      "Ara.",
    ],
    weekDayNames: ["Pa", "Sa", "ÇA", "Pe", "Cu", "Cum", "Paz"],
  },
};

export function areEqual(a, b) {
  if (!a || !b) return false;

  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

export function isLeapYear(year) {
  return !(year % 4 || (!(year % 100) && year % 400));
}

export function getDaysInMonth(date) {
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  return lastDay;
}

export function getDayOfWeek(date) {
  const dayOfWeek = date.getDay();

  return WEEK_DAYS_FROM_MONDAY[dayOfWeek];
}

export function getWeekData({ year, month, week, type }) {
  const firstDayOfMonth = new Date(year, month);

  const firstDayOrderByMonth = getDayOfWeek(firstDayOfMonth);

  let dayStartsOn = null;

  dayStartsOn = (week - 1) * DAYS_IN_WEEK + 1 - firstDayOrderByMonth;

  const result = [];

  for (let i = 0; i < DAYS_IN_WEEK; i++)
    result[i] = new Date(year, month, dayStartsOn + i);

  return result;
}

export function getCardEvents({ events, sorting = true }) {
  const currentCardEvents = [];

  // eslint-disable-next-line
  events?.forEach((event) => {
    if (!event) return;

    const { date } = event;

    if (!date) return;

    const eventDate = new Date(date),
      time = eventDate.getHours() * 60 + eventDate.getMinutes(),
      datelineTime = eventDate.getTime(); //.setHours(0, 0, 0, 0);

    // if (datelineTime < new Date().setHours(0, 0, 0, 0)) return;

    const dateTime = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      1
    ).getTime();

    const currentDateline = currentCardEvents.find(
      (dateline) => dateline.dateTime === dateTime
    );
    if (currentDateline) {
      // ! new -  separate card with time for "list" type
      // const currentTimeline = currentDateline.allEvents.find(
      //   (timeline) => timeline.datelineTime === datelineTime
      // );

      // currentTimeline
      //   ? currentTimeline.events.push(event)
      //   :

      currentDateline.allEvents.push({
        datelineTime,
        time,
        events: [event],
      });
    } else {
      currentCardEvents.push({
        dateTime,
        allEvents: [{ datelineTime, time, events: [event] }],
      });
    }
  });

  if (sorting) {
    const compareTimeline = (a, b) => {
      if (a.datelineTime > b.datelineTime) return 1;
      if (b.datelineTime > a.datelineTime) return -1;
      return 0;
    };
    currentCardEvents.map((dateline) =>
      dateline.allEvents.sort(compareTimeline)
    );
  }

  return currentCardEvents;
}

export function getWeekEvents({ events, type, timeClose }) {
  const currentWeekEvents = [];
  const closeTimeInHours = new Date(
    Date.parse(`0000-01-01T${timeClose}`)
  ).getHours();
  const closeTimeInMinutes = closeTimeInHours
    ? closeTimeInHours * 60
    : CLOSE_TIME;

  // console.log("events!", events);
  // eslint-disable-next-line
  events?.forEach((event) => {
    if (!event) return;
    const { date, duration } = event;

    const eventDate = new Date(date);

    if (type === "day") {
      const time = eventDate.getHours() * 60 + eventDate.getMinutes();
      // ! new -  separate card with time for "day" type
      const week = [];
      week.push(event);

      currentWeekEvents.push({ time, allEvents: week }); // allEvent: [{event}]
    } else if (type === "week") {
      const time = eventDate.getHours() * 60;
      const currentDayWeek = getDayOfWeek(eventDate);

      const amountHours = Math.ceil((eventDate.getMinutes() + duration) / 60);
      if (amountHours > 1) {
        for (let hour = 0; hour < amountHours; hour++) {
          const futureTime = time + hour * 60;

          if (futureTime > closeTimeInMinutes) {
            continue;
          }
          const currentTimeline = currentWeekEvents.find(
            (timeline) => timeline.time === futureTime
          );
          if (!currentTimeline) {
            const week = [];

            for (let i = 0; i < DAYS_IN_WEEK; i++) week.push([]);

            week[currentDayWeek].push(event);

            currentWeekEvents.push({ time: futureTime, allEvents: week });
          } else {
            currentTimeline.allEvents[currentDayWeek].push(event);
          }
        }
      } else {
        const currentTimeline = currentWeekEvents.find(
          (timeline) => timeline.time === time
        );

        if (!currentTimeline) {
          const week = [];

          for (let i = 0; i < DAYS_IN_WEEK; i++) week.push([]);

          week[currentDayWeek].push(event);

          currentWeekEvents.push({ time, allEvents: week });
        } else {
          currentTimeline.allEvents[currentDayWeek].push(event);
        }
      }
    }
  });

  const compareTimeline = (a, b) => {
    if (a.time > b.time) return 1;
    if (b.time > a.time) return -1;
    return 0;
  };
  currentWeekEvents.sort(compareTimeline);
  // console.log(currentWeekEvents, "currentWeekEvents");
  return currentWeekEvents;
}

export function getMonthData(year, month, events) {
  const currentDay = new Date(year, month);
  const currentMonthEvents = {};

  // eslint-disable-next-line
  events?.forEach((event) => {
    if (!event) return;
    const date = event.date;

    const currentDate = new Date(date);

    currentMonthEvents[currentDate.getDate()]
      ? currentMonthEvents[currentDate.getDate()].push(event)
      : (currentMonthEvents[currentDate.getDate()] = [event]);
  });

  const result = [];

  const daysInMonth = getDaysInMonth(currentDay);

  const monthStartsOn = getDayOfWeek(currentDay);

  const prevDaysCount = getDaysInMonth(new Date(year, month - 1));
  let day = 1;

  for (let i = 0; i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK; i++) {
    result[i] = [];

    for (let j = 0; j < DAYS_IN_WEEK; j++) {
      result[i].push([]);
      if (i === 0 && j < monthStartsOn) {
        result[i][j] = {
          date: new Date(
            year,
            month - 1,
            prevDaysCount - (monthStartsOn - j - 1)
          ),
          allEvents: null,
        };
      } else if (day > daysInMonth) {
        result[i][j] = {
          date: new Date(year, month + 1, day - daysInMonth),
          allEvents: null,
        };
        day++;
      } else {
        result[i][j] = {
          date: new Date(year, month, day),
          allEvents: currentMonthEvents[day],
        };
        day++;
      }
    }
  }

  return result;
}
