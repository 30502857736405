import React, { memo, useMemo } from "react";
import Link from "next/link";

import {
  TIME_TITLE,
  TIME_MINUTES_TITLE,
} from "./ScheduleWeek/languageConstants";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { clsx } from "clsx";

const TimelineWeek = memo(({ allEvents, time }) => {
  const [t] = useTranslation();
  const router = useRouter();

  const {
    query: { club },
  } = router;
  const timeline = useMemo(
    () =>
      ((time - (time % 60)) / 60).toString().padStart(2, 0) +
      ":" +
      (time % 60).toString().padStart(2, 0),
    [time]
  );

  return (
    <div className="schedule-week-timeline">
      <div className="schedule-week-timeline__time">{timeline}</div>
      <div className="schedule-week-timeline__wrapper">
        <div className="schedule-week-timeline__inner">
          {allEvents.map((events, idx) => {
            return (
              <div key={idx} className="schedule-week-timeline__day">
                {!!events.length &&
                  events?.map(
                    ({
                      id,
                      date,
                      duration,
                      enrollEnabled,
                      title,
                      color,
                      textColor,
                    }) => {
                      const eventDate = new Date(date);
                      const timeStart =
                        eventDate.getHours().toString().padStart(2, 0) +
                        ":" +
                        eventDate.getMinutes().toString().padStart(2, 0);

                      const isNotActive = !enrollEnabled;

                      const trainingHours = Math.floor(duration / 60),
                        trainingTime = !duration
                          ? ""
                          : duration < 60
                          ? `${duration} ${t(TIME_MINUTES_TITLE(duration))}`
                          : duration % 60 === 0
                          ? `${trainingHours} ${t(TIME_TITLE(trainingHours))}`
                          : `${trainingHours} ${t(TIME_TITLE(trainingHours))} 
      ${duration % 60} ${t(TIME_MINUTES_TITLE(duration % 60))}`;

                      const show =
                        (time - (time % 60)) / 60 === eventDate.getHours();

                      return (
                        (<Link
                          href={`/club/${club}/event/${id}`}
                          key={id}
                          className={clsx("workout-card", {
                            // "workout-card--continued": !show,
                            "workout-card--disabled": isNotActive,
                          })}
                          style={{
                            backgroundColor: color,
                            color: textColor,
                          }}>

                          <div className="workout-card-inner">
                            {show && (
                              <>
                                <div className="workout-card__activity">
                                  {title}
                                </div>
                                <div className="workout-card__time">
                                  {timeStart}, {trainingTime}
                                </div>
                              </>
                            )}
                          </div>

                        </Link>)
                      );
                    }
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default TimelineWeek;
