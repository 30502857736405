import React from "react";

import { useTranslation } from "next-i18next";

const TimelineEmpty = ({ noActiveVisits }) => {
  const [t] = useTranslation();

  return (
    <div className="schedule-empty">
      <div className="schedule-empty__text">
        {noActiveVisits ? t('not_enrolled_on_workout') :t("no_training_current_date")}
      </div>
    </div>
  );
};

export { TimelineEmpty };
