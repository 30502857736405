export const TIME_TITLE = (count) => {
  return count % 10 === 1
    ? "one_hour"
    : count % 10 >= 2 && count % 10 <= 4
    ? "few_hours"
    : "lot_hours";
};

export const TIME_MINUTES_TITLE = (count) => {
  return count % 10 === 1
    ? "one_minute"
    : count % 10 >= 2 && count % 10 <= 4
    ? "few_minutes"
    : "lot_minutes";
};
