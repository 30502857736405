import React, { useMemo, useState, memo, useEffect } from "react";
import clsx from "clsx";

import * as calendar from "../calendarUtil";
import ClubSearchSwiper from "../../ClubSearchSwiper";

import { TimelineEmpty } from "../TimelineEmpty";
import ScrollBox from "../../../../../common/ScrollBox";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { CircularProgressIndicator } from "../../../../../common/CircularProgressIndicator/CircularProgressIndicator";

const ScheduleMonth = memo(
  ({
    setMonthNav = Function.prototype,
    events,
    month,
    year,
    isShowCalendar,
    noActiveVisits,
  }) => {
    const {
      i18n: { language },
    } = useTranslation();

    const router = useRouter();
    const {
      query: { club },
    } = router;

    const { monthNames, weekDayNames } = useMemo(() => {
      const { monthNames, weekDayNames } = calendar.configLanguage[language];
      return { monthNames, weekDayNames };
    }, [language]);

    const currentDate = useMemo(() => new Date(), []);

    const [selectedDate, setSelectedDate] = useState(null);

    const { handleDayClick, handleMonthChange } = useMemo(
      () => ({
        handleMonthChange: (month, year) => setMonthNav(month, year),
        handleDayClick: (date) => setSelectedDate(date),
      }),
      []
    );

    const monthData = useMemo(() => {
      if (!year || (!month && month !== 0)) return;
      return calendar.getMonthData(year, month, events);
    }, [year, month, events]);

    const [monthNamesForReturn, setMonthNamesForReturn] = useState([]);
    useEffect(() => {
      const date = new Date(),
        activeYear = date.getFullYear();

      let id = -6,
        monthId = 6;
      const prevYearMonth = monthNames.slice(-6).map((name) => ({
        name,
        activeMonth: monthId++,
        id: id++,
        activeYear: activeYear - 1,
      }));

      id = 1;
      monthId = 0;
      const activeYearMonth = monthNames.map((name) => ({
        name,
        activeMonth: monthId++,
        id: id++,
        activeYear: +activeYear,
      }));

      monthId = 0;
      const nextYearMonth = monthNames.slice(0, 6).map((name) => ({
        name,
        activeMonth: monthId++,
        id: id++,
        activeYear: +activeYear + 1,
      }));

      setMonthNamesForReturn([
        ...prevYearMonth,
        ...activeYearMonth,
        ...nextYearMonth,
      ]);
    }, [monthNames]);

    // FIXME:
    return (
      <div className="schedule-calendar">
        <ClubSearchSwiper
          styles={"schedule-month-swiper"}
          type="month"
          newParams={{
            spaceBetween: 20,
          }}
        >
          {monthNamesForReturn.map(({ name, activeYear, id, activeMonth }) => {
            return (
              <div
                onClick={() => {
                  handleMonthChange(activeMonth, activeYear);
                }}
                className={
                  month === activeMonth && year === activeYear
                    ? "schedule-swiper-search-element active-schedule-swiper-search-element"
                    : "schedule-swiper-search-element"
                }
                key={id}
              >
                <span className={"schedule-swiper-search-element__month"}>
                  {name}
                </span>
                <span className={"schedule-swiper-search-element__year"}>
                  {activeYear}
                </span>
              </div>
            );
          })}
        </ClubSearchSwiper>

        <div className="schedule-month ">
          <div
            className="schedule-month__container"
            style={!events || !events.length ? { display: "block" } : {}}
          >
            {!isShowCalendar ? (
              <CircularProgressIndicator />
            ) : events && !!events.length ? (
              <ScrollBox>
                <div className="schedule-month__content">
                  <div className="schedule-month__weekdays schedule-month-weekdays">
                    {weekDayNames.map((name) => (
                      <div
                        className="schedule-month-weekdays__wrapper"
                        key={name}
                      >
                        <button className="schedule-month-weekdays__button">
                          {name}
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="schedule-month__days schedule-month-days">
                    {!!monthData.length &&
                      monthData.map((week, index) => (
                        <div key={index} className="schedule-month-days__week">
                          {week.map(({ date, allEvents }, index) => {
                            return (
                              <div
                                key={index}
                                className={clsx("schedule-month-days__day", {
                                  "schedule-month-days__day_today":
                                    calendar.areEqual(date, currentDate),
                                  "schedule-month-days__day_selected":
                                    calendar.areEqual(date, selectedDate),
                                })}
                                onClick={() => handleDayClick(date)}
                              >
                                <div className="day-date" data={date.getDate()}>
                                  {date.getDate()}
                                </div>
                                {allEvents &&
                                  allEvents.length &&
                                  allEvents.map(
                                    ({
                                      id,
                                      date,
                                      title: activityTitle,
                                      color,
                                      enrollEnabled,
                                      textColor,
                                    }) => {
                                      const time =
                                        new Date(date).getHours() +
                                        ":" +
                                        (
                                          "0" + new Date(date).getMinutes()
                                        ).slice(-2);

                                      const isNotActive = !enrollEnabled;

                                      return (
                                        <Link
                                          href={`/club/${club}/event/${id}`}
                                          key={id}
                                          className={clsx("workout-card", {
                                            "schedule-timeline--disable":
                                              isNotActive,
                                          })}
                                          style={{
                                            backgroundColor: color,
                                            color: textColor,
                                          }}
                                        >
                                          <div className="workout-card-inner">
                                            <div className="workout-card__time workout-card__time--nowrap">
                                              {time}
                                            </div>
                                            <div className="workout-card__activity">
                                              {activityTitle}
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    }
                                  )}
                              </div>
                            );
                            // :
                            // <div className="schedule-month-days__day" type="button" key={index}></div>
                          })}
                        </div>
                      ))}
                  </div>
                </div>
              </ScrollBox>
            ) : (
              <TimelineEmpty noActiveVisits={noActiveVisits} />
            )}
          </div>
        </div>
        <style jsx global>{`
          @import "../ScheduleWeek/ScheduleWeek.scss?5";
          @import "../ScheduleDay/ScheduleDay.scss?4";
          @import "../ScheduleCards/ScheduleCards.scss";
          @import "./ScheduleMonth.scss?5";
        `}</style>
      </div>
    );
  }
);

export default ScheduleMonth;
