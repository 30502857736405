export const cancelIsOver = ({
  isHistory,
  date,
  declineDeadline,
}) => {
  if (isHistory) return true;

  const currentDate = new Date();

  const eventDate = new Date(date);

  if (declineDeadline)
    eventDate.setHours(eventDate.getHours() - declineDeadline);

  return currentDate - eventDate > 0;
};

const NON_SHOWABLE_PAIDS = [-1, 3, 5];

export const isShowablePriceByPaid = (paid) => {
  return !NON_SHOWABLE_PAIDS.includes(paid);
};

export const infoTextByCard = (paidByCard, monthNamesWithCapital) => {
  if (!paidByCard) return;
  const {
    id,
    dueDate,
    template: {
      title,
      subtitle,
      group: { title: groupTitle },
    },
  } = paidByCard;

  const date = new Date(dueDate),
    dateText = `${date.getDate()} ${
      monthNamesWithCapital[date.getMonth()]
    } ${date.getFullYear()}`;

  return `${id} / ${groupTitle} / ${title} ${subtitle} / ${dateText}`;
};
