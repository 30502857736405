import { useState, useEffect } from "react";

export default function useFilteredEvents({
  allEvents,
  isEveningTime,
  isAfternoonTime,
  isMorningTime,
  activity,
  instructor,
  template,
  time,
  isMyVisit,
  isAuthorized,
  activeBlockOfContent,
  dayStartTime,
  eveningStartTime,
}) {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (activeBlockOfContent == "list") return setEvents(allEvents);

    if (!allEvents?.length) return setEvents([]);

    const filteredEvents = () => {
      const timeIsValid = (time, morning, afternoon, evening) => {
        const MORNING_TIME = time >= 0 && time < dayStartTime;
        const AFTERNOON_TIME = time >= dayStartTime && time < eveningStartTime;
        const EVENING_TIME = time >= eveningStartTime && time <= 23;

        if (
          (morning && afternoon && evening) ||
          (!morning && !afternoon && !evening)
        )
          return true;

        if (
          (morning && MORNING_TIME) ||
          (afternoon && AFTERNOON_TIME) ||
          (evening && EVENING_TIME)
        )
          return true;

        return false;
      };

      const filteredByTime = /[0-9]{2}:[0-9]{2}/.test(time)
        ? allEvents.filter(({ date }) => {
            const [hours, minutes] = time.split(":");
            const eventDate = new Date(date);
            const eventHours = eventDate.getHours();
            const eventMinutes = eventDate.getMinutes();
            return +hours === eventHours && +minutes === eventMinutes;
          })
        : allEvents;

      const filtered = filteredByTime.filter(
        ({
          date,
          instructors,
          activity: { id: activityId } = {},
          template: { id: templateId } = {},
        }) => {
          const setOfInstructors = instructors?.map(({ id }) => id);

          const result =
            (!Number(activity) || activityId == activity) &&
            (!Number(template) || templateId == template) &&
            (!Number(instructor) || setOfInstructors?.includes(instructor)) &&
            timeIsValid(
              new Date(date).getHours(),
              isMorningTime,
              isAfternoonTime,
              isEveningTime
            );

          return result;
        }
      );

      return !isAuthorized || !isMyVisit
        ? filtered
        : filtered.filter(({ visitSet }) => !!visitSet?.length);
    };

    setEvents(filteredEvents());
  }, [
    allEvents,
    isEveningTime,
    isAfternoonTime,
    isMorningTime,
    activity,
    instructor,
    template,
    isMyVisit,
    time,
    activeBlockOfContent,
    dayStartTime,
    eveningStartTime,
  ]);

  return { events };
}
