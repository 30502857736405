import React, {
  memo,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";

import {
  SLIDER_NEXT_BUTTON_SVG,
  SLIDER_PREV_BUTTON_SVG,
} from "../../../../../constants/svgConstants";
import { clsx } from "clsx";

const params = {
  slidesPerView: "auto",
  speed: 500,
};

function ClubSearchSwiper({
  children,
  styles,
  onPrevPress = Function.prototype,
  onNextPress = Function.prototype,
  type,
  newParams = {},
  blockStyles,
  showArrows,
}) {
  const typeOfChildren = useMemo(() => {
    if (type === "week") return true;
  }, [type]);

  const swiperEl = useRef(null);

  const goNext = useCallback(() => {
    const swiper = swiperEl?.current?.swiper;
    if (!swiper) return;

    swiper.slideNext();
    onNextPress();
  }, []);

  const goPrev = useCallback(() => {
    const swiper = swiperEl?.current?.swiper;
    if (!swiper) return;

    swiper.slidePrev();
    onPrevPress();
  }, []);

  // Ссылка на родительский компонент и внутренний компонент (для того чтобы узнать ширину)
  const mainBlockRef = useRef(null);
  const smallSearchRef = useRef(null);

  // Показывать елементы в слайдере или в обычном блоке
  const [showSwiper, setShowSwiper] = useState(false);

  // Проверяем при 1 рендере
  useEffect(() => {
    if (!smallSearchRef?.current || !mainBlockRef?.current) return;

    const mainWidth = mainBlockRef.current.scrollWidth;
    const widthSmallEl = smallSearchRef.current.scrollWidth;

    setShowSwiper(widthSmallEl > mainWidth ? true : false);
  }, [mainBlockRef, smallSearchRef, children]);

  // добавляем проверку при resize
  useEffect(() => {
    const resizeFunc = () => {
      if (!smallSearchRef?.current || !mainBlockRef?.current) return;

      const mainWidth = mainBlockRef.current.scrollWidth;
      const widthSmallEl = smallSearchRef.current.scrollWidth;

      if (widthSmallEl > mainWidth && showSwiper === false) {
        setShowSwiper(true);
      } else if (widthSmallEl <= mainWidth && showSwiper === true) {
        setShowSwiper(false);
      }
    };

    window.addEventListener("resize", resizeFunc);

    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [smallSearchRef, mainBlockRef, showSwiper]);

  return (
    <div
      className={clsx("club-swiper-search-block", { [styles]: styles })}
      style={{ ...blockStyles }}
      ref={mainBlockRef}
    >
      {showSwiper || typeOfChildren ? (
        <div className="club-swiper-search-wrapper">
          {showArrows && (
            <>
              <div
                onClick={goNext}
                className={
                  "swiper-only-arrow-button-next " + "swiper-only-arrow-button"
                }
              >
                {SLIDER_NEXT_BUTTON_SVG("#CACACA")}
              </div>
              <div
                onClick={goPrev}
                className={
                  "swiper-only-arrow-button-prev " + "swiper-only-arrow-button"
                }
              >
                {SLIDER_PREV_BUTTON_SVG("#CACACA")}
              </div>
            </>
          )}

          <Swiper
            {...{ ...params, ...newParams }}
            shouldSwiperUpdate={true}
            ref={swiperEl}
          >
            {children}
          </Swiper>
        </div>
      ) : (
        <div className="small-search-el-block" ref={smallSearchRef}>
          {children}
        </div>
      )}
      <style jsx global>{`
        @import "./ClubsSearchSwiper.scss";
      `}</style>
    </div>
  );
}

ClubSearchSwiper.propTypes = {
  allCardTemplateGroups: PropTypes.array,
  setActiveTemplateGroupeId: PropTypes.func,
  blockStyles: PropTypes.object,
  showArrows: PropTypes.bool,
};

ClubSearchSwiper.defaultProps = {
  allCardTemplateGroups: [],
  setActiveTemplateGroupeId: () => {},
  blockStyles: {},
  showArrows: true,
};

export default memo(ClubSearchSwiper);
