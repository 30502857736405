import React, { useRef } from "react";
import PropTypes from "prop-types";

import useScrollBox from "./useScrollBox";

import {
  SLIDER_NEXT_BUTTON_SVG,
  SLIDER_PREV_BUTTON_SVG,
} from "../../../constants/svgConstants";

const ScrollBox = ({ children }) => {
  const scrollWrapperRef = useRef(null);
  const { isDragging, onClickNext, onClickPrev } =
    useScrollBox(scrollWrapperRef);

  return (
    <div className="scroll-box">
      <>
        <div
          onClick={onClickNext}
          // onClick={goNext}
          className={
            "scroll-box__arrow-button-next " + "scroll-box__arrow-button"
          }
        >
          {SLIDER_NEXT_BUTTON_SVG("#CACACA")}
        </div>
        <div
          onClick={onClickPrev}
          // onClick={goPrev}
          className={
            "scroll-box__arrow-button-prev " + "scroll-box__arrow-button"
          }
        >
          {SLIDER_PREV_BUTTON_SVG("#CACACA")}
        </div>
      </>

      <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
        <div
          className="scroll-box__container"
          role="list"
          style={{
            pointerEvents: isDragging ? "none" : undefined,
            userSelect: isDragging ? "none" : "auto",
          }}
        >
          {children}
        </div>
      </div>
      <style jsx global>{`
        @import "./ScrollBox.scss";
      `}</style>
    </div>
  );
};

ScrollBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollBox;
